import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const NightP = [
{
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799678/rod-website-images/digital/night/xvwmzpnoyoknfhomlqu6.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799679/rod-website-images/digital/night/xzyoxsmzk1chwkukaaqp.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799679/rod-website-images/digital/night/ucnz847pgf7vqnzpdeed.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799679/rod-website-images/digital/night/fk0xb2b1rxcxq2h9dr62.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799678/rod-website-images/digital/night/ftmylja2yyzsdamja1qt.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },

]