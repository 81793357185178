import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const Yvtp = [
    {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799691/rod-website-images/digital/yvtp/fr754q1fg87zd4fszru7.png",
        // width: 320,
        height: 300,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799691/rod-website-images/digital/yvtp/fbmoforkcnwuzeu8ui6u.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799691/rod-website-images/digital/yvtp/egnn7urnysowjptfo2u3.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799692/rod-website-images/digital/yvtp/fqdqpdwcgfcriq4x30ep.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799693/rod-website-images/digital/yvtp/nxklrye5dylbademncxo.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799693/rod-website-images/digital/yvtp/susbvznivni9hlpftosu.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799693/rod-website-images/digital/yvtp/v1jutxhyny0xcu6sytra.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799694/rod-website-images/digital/yvtp/mhydkyeytaiwcttiudav.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799694/rod-website-images/digital/yvtp/jwsbkslmcvseargtkuee.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799694/rod-website-images/digital/yvtp/h5t3fpa7vof6spio9rvb.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799695/rod-website-images/digital/yvtp/ybqawqtmzrlu8va0krnx.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799695/rod-website-images/digital/yvtp/ac3t4lv2cs3okr7ktmg7.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799696/rod-website-images/digital/yvtp/hvs4wzlka3gbv4ndhugd.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
]