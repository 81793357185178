import React from 'react'
import CsProjects from '../CsProjects'

function Cs() {
  return (
    <>
        <CsProjects />
    </>
  )
}

export default Cs