import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const Katie = [
{
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799708/rod-website-images/digital/kt/fmi1uqg55wetcm9c1dsi.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799709/rod-website-images/digital/kt/b2kz4eaphl9qn6lniiae.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799710/rod-website-images/digital/kt/dpwb1wyerwylctrhjszl.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799710/rod-website-images/digital/kt/lm6umttmghudzbi8yihe.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799711/rod-website-images/digital/kt/tmr639tp4wryuntdlibe.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799711/rod-website-images/digital/kt/rmn8hn1zvfmtkur2htxw.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799712/rod-website-images/digital/kt/av5zjkn4m6oyjwofxmfy.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799712/rod-website-images/digital/kt/n5iqx8hazbjwmwa6jjah.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799712/rod-website-images/digital/kt/ghyxmobaisvjuqpjzls5.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },

]