import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const Graduation = [
{
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799696/rod-website-images/digital/grad/dgraltynqo8nos3babpa.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799700/rod-website-images/digital/grad/zlyjoq5naifxkxssx2yc.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799702/rod-website-images/digital/grad/xrf699iqkpzhlst3un1f.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799700/rod-website-images/digital/grad/nsmrhmc4szfdoekrfe89.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799698/rod-website-images/digital/grad/zs0kzzn1cd1wgp3cx9dh.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799701/rod-website-images/digital/grad/icfwbp8g4qbskvsuwjvd.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799700/rod-website-images/digital/grad/hhewepnswbaelffs3dfx.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799699/rod-website-images/digital/grad/wjudzqrbs2omlty0kjsx.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799698/rod-website-images/digital/grad/h1dq7fujtqqgtviqtxlo.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799699/rod-website-images/digital/grad/z6m0q2ouhgmxoqkirb2o.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799697/rod-website-images/digital/grad/xic4civc7q1se2j47qlx.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799698/rod-website-images/digital/grad/od0fn3lkxtuhkbisnuiw.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799701/rod-website-images/digital/grad/vvl0h8ycsqpuktqruhvn.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799697/rod-website-images/digital/grad/rax7ea8z42pz36dqpek6.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799702/rod-website-images/digital/grad/owla51gisx3uimjnkfwh.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799702/rod-website-images/digital/grad/nf6f7wani5hbspghdrkc.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
]