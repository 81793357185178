import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const FFriends = [

{
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799714/rod-website-images/film/friends/hsvhuibk6wkj6dvah2mc.png",
    // width: 320,
    // height: 500,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1718138046/rod-75-kt-car_ejo86n.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799713/rod-website-images/film/friends/uwdbkndkkvyjigo5vnyo.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1718138045/rod-72_hwuzvt.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1718138042/rod-68_iwpkrr.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799713/rod-website-images/film/friends/ceip34gayex5rsfldh18.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1718138041/rod-53-ski-friends_j6d0wy.png",
    // width: 500,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1718138036/rod-2_zhy8oy.png",
    // width: 320,
    // height: 500,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1718138044/rod-71_xr3rto.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1718138043/rod-69_i4798n.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },


]