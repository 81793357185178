import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const ScubaP = [
    {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799680/rod-website-images/digital/scuba/xdojpjmgl5js15sdcojk.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799683/rod-website-images/digital/scuba/ofnpyxy9uiodx0j6edyp.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799683/rod-website-images/digital/scuba/l6jc9ivyn1ihoqndhyke.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799680/rod-website-images/digital/scuba/oim2pkgsx0exv51fkyxv.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799681/rod-website-images/digital/scuba/g4ihq2lmctq3davop6yk.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799681/rod-website-images/digital/scuba/jxa7yqfatmbxu9eh3c2x.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799681/rod-website-images/digital/scuba/rm0hshjreuisazqgvn0p.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799681/rod-website-images/digital/scuba/vjuypu9y8rhokanltpu3.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799682/rod-website-images/digital/scuba/lviqtbb0acn8hytnzyyi.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
]