import React from 'react'
import AllArts from '../AllArts'

function Arts() {
  return (
    <div>
      <AllArts />
    </div>
  )
}

export default Arts