import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const InConvo = [
    {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799687/rod-website-images/digital/icwr/jy93dd78h1qumgbksxjo.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799687/rod-website-images/digital/icwr/vbetijbt27i87dynd5ak.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799688/rod-website-images/digital/icwr/x8nz2u8o6moxkdwugarv.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799689/rod-website-images/digital/icwr/bfyurucwqqug7sittj8c.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799689/rod-website-images/digital/icwr/kz7pyudylcglaf8mmc0d.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799689/rod-website-images/digital/icwr/nz2irq9lyxgiogwzlzm1.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799689/rod-website-images/digital/icwr/z4xoimkxvcvt6b7apife.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799689/rod-website-images/digital/icwr/zxrjgvymtlgtxqnjc2l4.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799690/rod-website-images/digital/icwr/n3omslxfgmaj2wattyxt.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },
      {
        src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799691/rod-website-images/digital/icwr/sdpcwoi4lo4hh5mafp3q.png",
        // width: 320,
        // height: 174,
        // tags: [
        //   { value: "Nature", title: "Nature" },
        //   { value: "Flora", title: "Flora" },
        // ],
        // caption: "After Rain (Jeshu John - designerspics.com)",
      },


]