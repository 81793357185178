import {Cloudinary} from "@cloudinary/url-gen";

const cld = new Cloudinary({cloud: {cloudName: 'dxewrioco'}});

export const BoulderingP = [

{
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799714/rod-website-images/film/bouldering/gwy0zrdc3hnxafb53zue.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799715/rod-website-images/film/bouldering/jlglae3kmcwzkmpdd5cn.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799715/rod-website-images/film/bouldering/agur8rkxoiuhfgo7icur.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799716/rod-website-images/film/bouldering/lkerl6z7bnalrxqzgywy.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799718/rod-website-images/film/bouldering/tsrcixyjqtquyofty0yx.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799718/rod-website-images/film/bouldering/juz74m9dkn2tojjbiwve.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://res.cloudinary.com/dxewrioco/image/upload/v1717799719/rod-website-images/film/bouldering/tszc334b437xnwhar2u4.png",
    // width: 320,
    // height: 174,
    // tags: [
    //   { value: "Nature", title: "Nature" },
    //   { value: "Flora", title: "Flora" },
    // ],
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
]