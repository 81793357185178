import React from 'react'
import DigitalProjs from '../DigitalProjs'

function Digital() {
  return (
    <div>
        <DigitalProjs />
    </div>
  )
}

export default Digital